
    import {defineComponent, reactive, toRefs} from 'vue';
    import {ElMessage} from "element-plus/es";
    import {useRouter} from "vue-router";
    import {FOLDERS, LIST_RECURSIVE_PARENTS, RECALL, TRASH} from '@/constants/api';

    export default defineComponent({
        name: 'Trash',
        setup() {
            const state = reactive({
                dialogTableVisible: false,
            })
            const router = useRouter();
            const goBack = () => {
                router.back();
            }

            return {
                ...toRefs(state),
                goBack,
            }
        },
        data() {
            return {
                currentFolder: {
                    id: null, //If get a large number ID, must change ID as String.
                },
                folders: [],
                notes: [],
                tableData: [],
                dialogTableCurrentFolder: {
                    id: null, //If get a large number ID, must change ID as String.
                },
                dialogTableData: [],
                dialogRecursiveParents: [],
                recallItem: {
                    id: null,
                    fileType: null
                }
            }
        },
        mounted() {
            this.list();
        },
        methods: {
            list() {
                const vm = this;
                this.axios.get(TRASH, {
                    params: {
                        teamId: this.$store.state.currentTeam?.id
                    }
                }).then(function(response) {
                    vm.$data.tableData = [];
                    vm.$data.tableData = vm.$data.tableData.concat(response.data.folders);
                    vm.$data.tableData = vm.$data.tableData.concat(response.data.notes);
                    vm.$data.folders = response.data.folders;
                    vm.$data.notes = response.data.notes;
                }).catch(function (error) {
                    const data = error.response.data;
                    if (data != null) {
                        ElMessage({
                            showClose: true,
                            message: error.response.data.error,
                            type: 'error',
                        });
                    } else {
                        ElMessage({
                            showClose: true,
                            message: '获取数据失败',
                            type: 'error',
                        });
                    }
                })
            },
            initDataForDialog(parentFolderId: number | string | null = null) {
                this.listForDialog(parentFolderId);
                this.dialogRecursiveParents = [];
                this.listRecursiveParentsForDialog(parentFolderId);
            },
            listRecursiveParentsForDialog(currentFolderId: number | string | null = null) {
                if (!currentFolderId) {
                    return
                }
                const vm = this;
                this.axios.get(LIST_RECURSIVE_PARENTS, {
                    params: {
                        currentFolderId: currentFolderId,
                        teamId: this.$store.state.currentTeam?.id
                    }
                }).then(function(response) {
                    vm.$data.dialogRecursiveParents = response.data.parents;
                }).catch(function (error) {
                    const data = error.response.data;
                    if (data != null) {
                        ElMessage({
                            showClose: true,
                            message: error.response.data.error,
                            type: 'error',
                        });
                    } else {
                        ElMessage({
                            showClose: true,
                            message: '获取面包屑数据失败',
                            type: 'error',
                        });
                    }
                })
            },
            listForDialog(parentFolderId: number | string | null = null) {
                const vm = this;
                this.axios.get(FOLDERS, {
                    params: {
                        parentFolderId: parentFolderId,
                        teamId: this.$store.state.currentTeam?.id
                    }
                }).then(function(response) {
                    vm.$data.dialogTableCurrentFolder = response.data.currentFolder;
                    vm.$data.dialogTableData = response.data.folders;
                    vm.$data.dialogTableData = vm.$data.dialogTableData.concat(response.data.notes);
                }).catch(function (error) {
                    if (error.response) {
                        if (error.response.status == 404) {
                            ElMessage({
                                showClose: true,
                                message: '原文件夹不存在！',
                                type: 'warning',
                            });
                            vm.initDataForDialog();
                        }
                    } else if (error.response.data != null) {
                        ElMessage({
                            showClose: true,
                            message: error.response.data.error,
                            type: 'error',
                        });
                    } else {
                        ElMessage({
                            showClose: true,
                            message: '获取数据失败',
                            type: 'error',
                        });
                    }
                })
            },
            dialogVisit(row: any) {
                this.listForDialog(row.id);
                this.listRecursiveParentsForDialog(row.id);
            },
            recall(row: any) {
                this.recallItem = row;
                this.dialogTableVisible = true;
                this.initDataForDialog(row.parentId);
            },
            dialogRecallTo() {
                const vm = this;
                const targetFolderId = this.$data.dialogTableCurrentFolder.id;
                const payload = {
                    id: vm.recallItem.id,
                    fileType: vm.recallItem.fileType,
                    targetFolderId: targetFolderId,
                    teamId: this.$store.state.currentTeam?.id
                }
                this.axios.patch(RECALL, payload).then(function(response) {
                    ElMessage({
                        showClose: true,
                        message: '恢复成功',
                        type: 'success',
                    });
                    vm.dialogTableVisible = false;
                    vm.list();
                }).catch(function (error) {
                    const data = error.response.data;
                    if (data != null) {
                        ElMessage({
                            showClose: true,
                            message: error.response.data.error,
                            type: 'error',
                        });
                    } else {
                        ElMessage({
                            showClose: true,
                            message: '恢复失败',
                            type: 'error',
                        });
                    }
                })
            }
        }
    });
